import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, tap, distinctUntilChanged, switchMap, shareReplay } from 'rxjs/operators';

import { IlluminationType, LensOptions } from '../enums';
import { EquipmentValues, Projector, Lamp, Lens, EquipmentItem, DynamicRatiosValues } from '../models';
import { filterNull } from '../rxjs/filter-null';
import { combineValues, onMessageOrFailed, pickProperties, pickValue } from '../rxjs/public_api';

import { ProductsService } from './api/products.service';
import { CalculationsFormService } from './calculations-form.service';
import { DynamicRatiosService } from './dynamic-ratios.service';
import { EfficiencyPercentCalculationsService } from './efficiency-percent-calculations.service';
import { LoadingService } from './loading.service';
import { LumensRequiredService } from './lumens-required.service';

interface SortableItem {
  /** Item order value. */
  order: number;
}

/**
 * Equipment service.
 */
@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  private readonly formValues$ = new BehaviorSubject<EquipmentValues | null>(null);

  /** Equipment values. */
  public readonly values$ = this.formValues$.asObservable().pipe(filterNull());

  /**
   * @constructor
   */
  constructor(
    private readonly productsService: ProductsService,
    private readonly calculationsFormService: CalculationsFormService,
    private readonly loadingService: LoadingService,
    private readonly ratiosService: DynamicRatiosService,
    private readonly efficiencyService: EfficiencyPercentCalculationsService,
    private readonly lumensRequiredService: LumensRequiredService,
  ) { }

  private readonly selectedLensOption$ = this.calculationsFormService.values$.pipe(pickValue('lensOptions'));

  private readonly lumensAvailableWithHeadroomBase$ = combineValues([
    this.efficiencyService.values$.pipe(pickProperties('lensEfficiencyPercent', 'lumensHeadroomPercent')),
    this.calculationsFormService.values$.pipe(pickValue('numberOfProjectors')),
  ]).pipe(
    // Lens efficiency will be 0 until no lens option is selected.
    map(([{ lensEfficiencyPercent, lumensHeadroomPercent }, numberOfProjectors]) => {
      // Note: We divide by 100 because we use percent.
      return lensEfficiencyPercent / 100 * numberOfProjectors * (100 - lumensHeadroomPercent) / 100;
    }),
  );

  /** Lumens available. */
  public readonly lumensAvailable$ = combineValues([
    this.lumensAvailableWithHeadroomBase$,
    this.values$.pipe(pickValue('projector'), filterNull()),
  ]).pipe(
    map(([lumensAvailableBase, projector]) => {
      const lumensWithHeadroomBase = lumensAvailableBase * projector.colorCorrectionEfficiencyPercent / 100;
      return this.calculateLumensAvailableWithHeadroom(projector.maxLumens, lumensWithHeadroomBase);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly overallMinLumensRequired$ = combineValues([
    this.lumensRequiredService.values$,
    this.calculationsFormService.values$.pipe(pickValue('include3D')),
  ]).pipe(
    map(([{ flat, flat3D, scope, scope3D }, include3D]) => {
      if (include3D) {
        return Math.max(flat, flat3D, scope, scope3D);
      }

      return Math.max(flat, scope);
    }),
  );

  /**
   * Available Projectors.
   */
  public readonly projectors$ = this.calculationsFormService.values$.pipe(
    pickValue('projectorType'),
    filterNull(),
    distinctUntilChanged(),
    tap(() => this.loadingService.startEquipment()),
    switchMap(type => this.productsService.getProjectors(type)),
    map(items => items.sort(this.sortByOrder)),
    onMessageOrFailed(() => this.loadingService.finishEquipment()),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Auto selected projector. */
  public readonly projectorAutoSelect$ = combineValues([
    this.projectors$,
    this.lumensAvailableWithHeadroomBase$,
    this.overallMinLumensRequired$,
  ]).pipe(
    map(([projectors, lumensAvailableWithHeadroomBase, minLumens]) => {
      const filteredList = this.filterItemsWithZeroOrder(projectors);
      const sortedProjectors = filteredList.sort((a, b) => a.maxLumens - b.maxLumens);

      return this.selectProjectorWithAppropriateLumensAvailable(sortedProjectors, minLumens, lumensAvailableWithHeadroomBase);
    }),
  );

  /**
   * List with all lenses for projectors with selected type.
   */
  public readonly allLensesList$ = this.projectors$.pipe(
    map(list => list.reduce<Lens[]>((acc, item) => [...acc, ...item.lenses], [])),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private readonly lensesList$ = combineValues([
    this.values$.pipe(pickValue('projector')),
    this.allLensesList$,
  ]).pipe(
    map(([projector, lensesList]) => projector ? (projector?.lenses || lensesList) : []),
  );

  /** Available Lenses for selected projector. */
  public readonly lenses$ = combineValues([
    this.lensesList$,
    this.selectedLensOption$,
    this.ratiosService.values$,
  ]).pipe(
    map(([items, lensOption, zoom]) => {
      const filteredList = this.filterLensesByType(items, lensOption);

      return filteredList.map<EquipmentItem<Lens>>(lens => ({
        ...lens,
        isSuitable: lens.maxZoom <= zoom.maxZoomRequired && lens.minZoom >= zoom.minZoomRequired,
      }));
    }),
    map(items => items.sort(this.sortByOrder)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Auto selected lens. */
  public readonly lensAutoSelect$ = combineValues([
    this.lenses$,
    this.ratiosService.values$,
  ]).pipe(
    map(([lenses, ratios]) => {
      const suitableLenses = lenses.filter(lens => lens.isSuitable);
      const filteredList = this.filterItemsWithZeroOrder(suitableLenses);
      return this.getAutoSelectedLens(filteredList, ratios);
    }),
  );

  /** Available Lamps for selected projector. */
  public readonly lamps$ = this.values$.pipe(
    pickValue('projector'),
    map(projector => projector ? projector.lamps : []),
    map(items => items.sort(this.sortByOrder)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Lamp auto select. */
  public readonly lampAutoSelect$ = combineValues([
    this.lamps$,
    this.lumensAvailableWithHeadroomBase$,
    this.overallMinLumensRequired$,
    this.values$.pipe(pickValue('projector')),
  ]).pipe(
    map(([lamps, lumensAvailableBase, lumensRequired, projector]) => {
      const filteredList = this.filterItemsWithZeroOrder(lamps);
      const sortedLamps = filteredList
        .sort((a, b) => b.warrantyHours - a.warrantyHours)
        .sort((a, b) => a.maxLumens - b.maxLumens);
      if (projector) {
        const lumensAvailable = lumensAvailableBase * projector.colorCorrectionEfficiencyPercent / 100;
        return this.selectLampWithAppropriateLumensAvailable(sortedLamps, lumensAvailable, lumensRequired);
      }
      return null;
    }),
  );

  /** Should display Lamp selection or not. */
  public readonly shouldSelectLamp$ = this.calculationsFormService.illumination$.pipe(
    map(illumination => illumination === IlluminationType.Xenon),
  );

  /**
   * Set equipment values.
   * @param value New values.
   */
  public setValues(value: EquipmentValues): void {
    this.formValues$.next(value);
  }

  private selectLampWithAppropriateLumensAvailable(
    list: Lamp[], lumensAvailableBase: number, lumensRequired: number,
  ): Lamp | null {
    for (const item of list) {
      const lumensWithHeadroom = this.calculateLumensAvailableWithHeadroom(item.maxLumens, lumensAvailableBase);

      /**
       * For lamp auto select we have to calculate Lumens Available with Headroom first.
       * Lamp selected "Lumens Available with Headroom" must be greater than the "Lumens Required".
       */
      if (lumensWithHeadroom > lumensRequired) {
        return item;
      }
    }

    return null;
  }

  private selectProjectorWithAppropriateLumensAvailable(
    list: Projector[], lumensRequired: number, lumensAvailableWithHeadroomBase: number,
  ): Projector | null {
    for (const item of list) {
      const lumensAvailable = item.colorCorrectionEfficiencyPercent / 100 * lumensAvailableWithHeadroomBase;
      const lumensWithHeadroom = this.calculateLumensAvailableWithHeadroom(item.maxLumens, lumensAvailable);

      /**
       * For projector auto select we have to calculate Lumens Available with Headroom first.
       * Projector selected "Lumens Available with Headroom" must be greater than the "Lumens Required".
       */
      if (lumensWithHeadroom > lumensRequired) {
        return item;
      }
    }

    return null;
  }

  private calculateLumensAvailableWithHeadroom(maxLumens: number, lumensWithHeadroomBase: number): number {
    return maxLumens * lumensWithHeadroomBase;
  }

  private filterLensesByType(items: Lens[], lensOption: LensOptions | null): Lens[] {
    if (lensOption === null) {
      return items;
    }

    return items.filter(item => item.type === lensOption);
  }

  private sortByOrder(first: SortableItem, second: SortableItem): number {
    return first.order - second.order;
  }

  /**
   * All items with order equals 0 will be removed from the list.
   * @param items Items list to filter.
   */
  private filterItemsWithZeroOrder<T extends SortableItem>(items: T[]): T[] {
    return items.filter(item => item.order !== 0);
  }

  private getAutoSelectedLens(lenses: readonly EquipmentItem<Lens>[], ratios: DynamicRatiosValues): EquipmentItem<Lens> | null {
    if (lenses.length === 0) {
      return null;
    }

    return lenses.reduce((bestLens, item) => {
      const bestLensZoom = this.calculateLensZoomRange(bestLens, ratios);
      const lensZoom = this.calculateLensZoomRange(item, ratios);
      if (lensZoom < bestLensZoom) {
        return item;
      }
      return bestLens;
    }, lenses[0]);
  }

  private calculateLensZoomRange(lens: EquipmentItem<Lens>, ratios: DynamicRatiosValues): number {
    // This formula was taken from the task: https://saritasa.atlassian.net/browse/CAE-5.
    const a = ratios.flatRatio - lens.maxZoom;
    const b = lens.minZoom - ratios.scopeRatio;
    return Math.abs(a - b);
  }
}
