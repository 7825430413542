import { LensCode } from './lens';

/** NOHD lens factors. */
export class NohdLensType {
  /** I factor. */
  public iFactor: number;
  /** Lens type. */
  public lensType: LensCode;
  /** S factor. */
  public sFactor: number;
  public constructor(data: ConstructorInitArg<NohdLensType>) {
    this.iFactor = data.iFactor;
    this.lensType = data.lensType;
    this.sFactor = data.sFactor;
  }
}
