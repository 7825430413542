import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { LensOptions, ProjectorType } from '../enums';
import { BrightnessValues } from '../models';
import { combineValues, pickProperties, pickValue } from '../rxjs/public_api';

import { CalculationsFormService } from './calculations-form.service';
import { EfficiencyPercentCalculationsService } from './efficiency-percent-calculations.service';
import { EquipmentService } from './equipment.service';
import { ImageAnalysisService } from './image-analysis.service';
import { LumensRequiredService } from './lumens-required.service';

/**
 * Service to calculate values for brightness.
 */
@Injectable({
  providedIn: 'root',
})
export class BrightnessCalculationsService {
  /**
   * @constructor
   * @param formService Form service.
   * @param equipmentService Equipment service.
   * @param lumensRequiredService Lumens required service.
   * @param efficiencyService Efficiency percent service.
   * @param imageService Image analysis service.
   */
  public constructor(
    private readonly formService: CalculationsFormService,
    private readonly equipmentService: EquipmentService,
    private readonly lumensRequiredService: LumensRequiredService,
    private readonly efficiencyService: EfficiencyPercentCalculationsService,
    private readonly imageService: ImageAnalysisService,
  ) { }

  private readonly formValuesForCalculations$ = this.formService.values$.pipe(
    pickProperties('centerBrightness', 'centerBrightness3D', 'systemEfficiency3D'),
  );

  private readonly maxLumensValues$ = this.equipmentService.values$.pipe(
    pickProperties('projector', 'lamp'),
    map(({ projector, lamp }) => {
      let maxLumens = 0;

      if (projector) {
        if (ProjectorType.isXenon(projector.type) && lamp) {
          maxLumens = lamp.maxLumens;
        } else {
          maxLumens = projector.maxLumens;
        }
      }

      return maxLumens;
    }),
  );

  private readonly lumensAvailableValue$ = combineValues([
    this.maxLumensValues$,
    this.efficiencyService.values$.pipe(pickValue('lensEfficiencyPercent')),
    this.formService.values$.pipe(pickValue('numberOfProjectors')),
  ]).pipe(
    map(([maxLumens, lensEfficiency, numberOfProjectors]) => {
      // Note: We must divide by 100 because we use percent.
      return maxLumens * (lensEfficiency / 100) * numberOfProjectors;
    }),
  );

  private readonly maxBrightnessCalculationBase$ = combineValues([
    this.lumensAvailableValue$,
    this.lumensRequiredService.lumensRequiredCalculationBase$,
  ]).pipe(
    map(([maxLumens, base]) => maxLumens * base),
  );

  private readonly maxBrightnessValues$ = combineValues([
    this.maxBrightnessCalculationBase$,
    this.imageService.values$,
    this.formValuesForCalculations$,
  ]).pipe(
    map(([base, { flatCenterLumenArea, scopeCenterLumenArea }, { systemEfficiency3D }]) => ({
      flat: base / flatCenterLumenArea,
      // Note: we divide by 100 because we use percent.
      flat3D: base * (systemEfficiency3D / 100) / flatCenterLumenArea,
      scope: base / scopeCenterLumenArea,
      scope3D: base * (systemEfficiency3D / 100) / scopeCenterLumenArea,
    })),
  );

  private readonly powerRequiredValues$ = combineValues([
    this.formValuesForCalculations$,
    this.maxBrightnessValues$,
  ]).pipe(
    map(([{ centerBrightness, centerBrightness3D }, { flat, scope, flat3D, scope3D }]) => ({
      flat: this.calculatePowerRequired(centerBrightness, flat),
      scope: this.calculatePowerRequired(centerBrightness, scope),
      flat3D: this.calculatePowerRequired(centerBrightness3D, flat3D),
      scope3D: this.calculatePowerRequired(centerBrightness3D, scope3D),
    })),
  );

  private readonly overallBrightnessHeadroomValues$ = combineValues([
    this.lumensAvailableValue$,
    this.lumensRequiredService.values$,
  ]).pipe(
    map(([availableLumens, { flat, flat3D, scope, scope3D }]) => ({
      flat: this.calculateBrightnessHeadroom(availableLumens, flat),
      flat3D: this.calculateBrightnessHeadroom(availableLumens, flat3D),
      scope: this.calculateBrightnessHeadroom(availableLumens, scope),
      scope3D: this.calculateBrightnessHeadroom(availableLumens, scope3D),
    })),
  );

  private readonly maxContrast$ = this.equipmentService.values$.pipe(
    map(({ projector, lens }) => {
      if (projector == null || lens == null) {
        return null;
      }

      if (LensOptions.isHighBrightnessOrPremium(lens.type)) {
        return projector.hbContrast;
      }

      if (LensOptions.isHighContrastOrPremium(lens.type)) {
        return projector.hcContrast;
      }

      if (LensOptions.isUltraHighContrastOrPremium(lens.type)) {
        return projector.uhcContrast;
      }

      return null;
    }),
  );

  /** Calculated values. */
  public readonly values$: Observable<BrightnessValues> = combineValues([
    this.maxBrightnessValues$,
    this.powerRequiredValues$,
    this.overallBrightnessHeadroomValues$,
    this.maxLumensValues$,
    this.maxContrast$,
  ]).pipe(
    map(([maxCenterBrightness, powerRequired, brightnessHeadroom, maxLumens, maxContrast]) => ({
      maxCenterBrightness,
      powerRequired,
      brightnessHeadroom,
      maxLumens,
      maxContrast,
    })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private calculatePowerRequired(centerBrightness: number, maxCenterBrightness: number): number {
    // Note: We nust multiply value by 100 becuase we calculate percent.
    const roundedValue = Number(maxCenterBrightness.toFixed(1));
    return Math.ceil(centerBrightness / roundedValue * 100);
  }

  private calculateBrightnessHeadroom(lumensAvailable: number, lumensRequired: number): number {
    // Note: We must multiply by 100 because we calculate percent.
    return (lumensAvailable - lumensRequired) / lumensAvailable * 100;
  }
}
