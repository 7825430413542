import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Language } from '@cpcac/common/core/enums';
import { MobileService } from '@cpcac/common/core/services';
import { DestroyableComponent } from '@cpcac/common/core/utils/destroyable';
import { TranslateService } from '@ngx-translate/core';

/**
 * App header component.
 */
@Component({
  selector: 'cpcac-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class AppHeaderComponent {
  /** Is Mobile sidebar opened */
  public isMobileSidebarOpened$ = this.mobileService.isMobileSidebarOpened$;

  /** Language */
  public Language = Language;

  /** Active language */
  public selectedLang = this.translate.defaultLang as Language;

  /** Help guide link. */
  public get helpGuideLink(): SafeUrl {
    if (Language.isZH(this.selectedLang)) {
      return this.domSanitizer.bypassSecurityTrustUrl('https://www.christiedigital.cn/lp/cinemaster-help-guide-library/');
    }
    const isEMEA = Language.isEMEA(this.selectedLang);
    const link = `https://www.christiedigital.com/${isEMEA ? 'en-gb/' : ''}lp/cinemaster-help-guide-library/`;
    return this.domSanitizer.bypassSecurityTrustUrl(link);
  }

  /** @constructor */
  constructor(
    private readonly translate: TranslateService,
    private readonly mobileService: MobileService,
    private readonly domSanitizer: DomSanitizer,
  ) {
  }

  /** Change language */
  public selectLang(lang: Language): void {
    this.selectedLang = lang;
    this.translate.use(lang);
  }

  /** Toggle Sidebar */
  public toggleSidebar(): void {
    this.mobileService.toggleSidebar();
  }

}
