/**
 * Feet to Meters ratio
 */
export const FEET_TO_METERS_RATIO = 3.28084;

/**
 * Est. brightness drop-off over 30K hours percent.
 */
export const BRIGHTNESS_DROP_OFF_PERCENT = 20;

/**
 * Image Safety Factor.
 */
export const IMAGE_SAFETY_FACTOR = 0;

/**
 * Average Color Correction Efficiency percent.
 */
export const COLOR_CORRECTION_EFFICIENCY_PERCENT = {
  XENON: 97,
  LASER: 95,
};

/**
 * Lens Efficiency percent.
 */
export const LENS_EFFICIENCY_PERCENT = {
  HB_LENSES: 100,
  HC_LENSES: 91,
  UHC_LENSES: 70,
};

export const NO_ACCESS_ZONE_METER = 1.0;
