import { Component, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EquipmentItem } from '@cpcac/common/core/models';
import { EquipmentBase } from '@cpcac/common/core/models/equipment-base';
import { ControlValueAccessorBase } from '@cpcac/common/shared/utils/control-value-accessor-base';

/**
 * Equipment selection card component.
 */
@Component({
  selector: 'cpcac-equipment-selection-card',
  templateUrl: './equipment-selection-card.component.html',
  styleUrls: ['./equipment-selection-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EquipmentSelectionCardComponent),
      multi: true,
    },
  ],
})
export class EquipmentSelectionCardComponent<T extends EquipmentItem<EquipmentBase>> extends ControlValueAccessorBase<T> {
  /** Options */
  @Input()
  public options?: T[];

  /** Card header text. */
  @Input()
  public title = '';

  /** Tip text. */
  @Input()
  public tipText = '';

  /** Message to show if item is empty. */
  @Input()
  public emptyItemMessage = '';

  /** Is empty. */
  @Input()
  public isEmpty = false;

  /** Compare with function. */
  public readonly compareWith = (first: T | null, second: T | null): boolean => first?.id === second?.id;
}
